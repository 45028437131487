import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { FormattedMessage } from 'react-intl';

import Heading from '../Heading';

import GiftIcon from '../../icons/gift.svg';

import styles from './OrderItemAddedSauce.styl';

const cx = classNames.bind(styles);

export default function OrderItemAddedSauce(props) {

  return (
    <div className={cx('OrderItemAddedSauce', props.className)}>
      <Heading className={cx('OrderItemAddedSauce__name')} level={5} tagName="div">
        + <FormattedMessage id="sauce" /> {props.name}
      </Heading>
      <div className={cx('OrderItemAddedSauce__gift')}>
        <GiftIcon width={16} height={16} />
      </div>
    </div>
  );
}

OrderItemAddedSauce.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string
};
