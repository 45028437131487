import React from 'react';
import PropTypes from 'prop-types';
import _sortBy from 'lodash/sortBy';
import _compact from 'lodash/compact';
import classNames from 'classnames/bind';
import { injectIntl } from 'react-intl';

import Heading from '../Heading';
import VariationInfo from '../VariationInfo';
import AddedIngredients from '../AddedIngredients';
import RemovedIngredients from '../RemovedIngredients';
import OrderItemAddedSauce from './OrderItemAddedSauce';

// Utils
import { isCombobox } from '../../utils/cart';

import styles from './OrderItemDescription.styl';

const cx = classNames.bind(styles);

function OrderItemDescriptionView(props) {

  const renderDescription = () => {
    const { intl, ...good } = props;

    const crust = good.item.stuffed_crust;
    const kindName = good.item.kind?.name;
    const sizeValue = good.item.size?.value;
    const sizeUnit = good.item.size?.unit;
    const size = sizeValue && sizeUnit ? `${sizeValue}&nbsp;${sizeUnit}` : null;
    const count = good.count > 1 ? `(${good.count})` : '';
    const description = _compact([size, count]).join(' ');

    return (
      <>
        <span
          dangerouslySetInnerHTML={{
            __html: `${kindName ? `${kindName}, ` : ''}${description}`,
          }}
        />
        {crust &&
          crust !== 'none' &&
          ` + ${intl.formatMessage({ id: `pizza.stuffedCrust.${crust}` }).toLowerCase()}`}
      </>
    );
  };

  const renderComboboxDescription = () => {
    const {
      item: { combo_box_items = [] },
    } = props;

    return (
      <div className={cx('OrderItemDescription__combobox')}>
        {_sortBy(combo_box_items, 'category_id').map(comboboxItem => (
          <div key={comboboxItem.id} className={cx('OrderItemDescription__comboboxItem')}>
            <Heading level={5}>{comboboxItem.custom_name || comboboxItem.name}</Heading>
            <VariationInfo
              {...comboboxItem}
              className={cx('OrderItemDescription__comboboxVariationInfo')}
            />
            {comboboxItem.removed_ingredients.length > 0 && (
              <RemovedIngredients list={comboboxItem.removed_ingredients} />
            )}
          </div>
        ))}
      </div>
    );
  };


  const { className, item = {}, type } = props;

    let added_ingredients = [];
    let removed_ingredients = [];

    if (type === 'combo') {
      added_ingredients = props.added_ingredients || [];
      removed_ingredients = props.removed_ingredients || [];
    } else {
      added_ingredients = item.added_ingredients || [];
      removed_ingredients = item.removed_ingredients || [];
    }

    const added_sauces = item.added_sauces || [];
    const name = item.custom_name || item.name || '';

    return (
      <div className={cx('OrderItemDescription', className)}>
        {isCombobox(props) ? (
          <>
            <Heading level={5}>{name}:</Heading>
            <div
              className={cx('OrderItemDescription__text', 'OrderItemDescription__text_combobox')}
            >
              {renderComboboxDescription()}
            </div>
          </>
        ) : (
          <>
            <Heading level={5}>{name}</Heading>
            <div className={cx('OrderItemDescription__text')}>{renderDescription()}</div>
          </>
        )}
        {added_ingredients.length > 0 && <AddedIngredients list={added_ingredients} />}
        {removed_ingredients.length > 0 && <RemovedIngredients list={removed_ingredients} />}
        {added_sauces.length > 0 && (
          <div className={cx('OrderItemDescription__AddedSauces')}>
            {added_sauces.map(sauce => (
              <OrderItemAddedSauce key={sauce.hash} {...sauce} />
            ))}
          </div>
        )}
      </div>
    );
}

OrderItemDescriptionView.defaultProps = {
  name: '',
  custom_name: '',
  added_ingredients: [],
  removed_ingredients: [],
  type: '',
  className: '',
};

OrderItemDescriptionView.propTypes = {
  item: PropTypes.object,
  type: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  custom_name: PropTypes.string,
  added_ingredients: PropTypes.array,
  removed_ingredients: PropTypes.array,
  intl: PropTypes.object,
};

export default injectIntl(OrderItemDescriptionView);
