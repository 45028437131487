import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Icons
import GiftIcon from '../../icons/gift.svg';

// Styles
import styles from './OrderPreview.styl';

const cx = classNames.bind(styles);

export default function OrderPreview(props) {
  const { items, className, big, onClick } = props;

  return (
    <button
      className={cx(
        'OrderPreview',
        { OrderPreview_big: big },
        { OrderPreview_clickable: onClick },
        className,
      )}
      onClick={onClick}
      type="button"
    >
      {items?.slice(0, 3)?.map(order => (
        <figure
          key={order.hash}
          className={cx('OrderPreview__image', {
            OrderPreview__image_disabled: order.upsale_status === false,
          })}
        >
          <img src={order.item.image} alt={order.item.name} />
          {order.count > 1 && <div className={cx('OrderPreview__count')}>x{order.count}</div>}
          {order.type === 'gift' && <GiftIcon className={cx('OrderPreview__gift')} />}
        </figure>
      ))}
      {items?.length > 3 && <div className={cx('OrderPreview__more')}>{items?.length - 3}+</div>}
    </button>
  );
}

OrderPreview.defaultProps = {
  className: '',
  items: [],
  big: false,
  onClick: undefined,
};

OrderPreview.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array,
  big: PropTypes.bool,
  onClick: PropTypes.func,
};
