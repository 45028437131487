import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import classNames from 'classnames/bind';
import { FormattedMessage } from 'react-intl';

// Icons
import CloseIcon from '../../icons/close.svg';

// Components
import Modal from '../Modal';
import Paper from '../Paper';
import Button from '../Button';

// State
import { clearCart } from '../../state/modules/cart/actions';

// Styles
import styles from './RepeatOrderConflict.styl';

const cx = classNames.bind(styles);

export default function RepeatOrderConflict(props) {
  const { className, isOpen, onClose, addToCart } = props;

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const onAddToCart = async () => {
    setIsLoading(true);
    await addToCart();
    onClose();
    setIsLoading(false);
  };

  const onClearCart = async () => {
    await dispatch(clearCart());
    await addToCart();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Paper className={cx('RepeatOrderConflict', className)}>
        <button className={cx('RepeatOrderConflict__closeButton')} onClick={onClose} type="button">
          <CloseIcon width={16} height={16} />
        </button>
        <div className={cx('RepeatOrderConflict__text')}>
          <FormattedMessage id="modal.orderConfict" />
        </div>
        <div className={cx('RepeatOrderConflict__actions')}>
          <Button className={cx('RepeatOrderConflict__actions__item')} onClick={onClearCart} link>
            <FormattedMessage id="button.clear" />
          </Button>
          <Button
            className={cx('RepeatOrderConflict__actions__item')}
            onClick={onAddToCart}
            isLoading={isLoading}
          >
            <FormattedMessage id="button.add" />
          </Button>
        </div>
      </Paper>
    </Modal>
  );
}

RepeatOrderConflict.defaultProps = {
  className: '',
};

RepeatOrderConflict.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  addToCart: PropTypes.func.isRequired,
};
