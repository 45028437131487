import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { FormattedMessage, injectIntl } from 'react-intl';

import AppLink from '../AppLink';
import ToggleArrow from '../ToggleArrow';
import Collapse from '../Collapse';
import OrderItemDescriptionView from './OrderItemDescriptionView';

import styles from './OrderItemDescription.styl';

const cx = classNames.bind(styles);

function OrderItemDescription(props) {

  const [isOpen, setIsOpen] = useState(false);

  const toggleComboComposition = e => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const { className, composition, view } = props;

  return (
    <>
      <Collapse isOpen={isOpen}>
        <div
          className={cx('OrderItemDescription__composition')}
          onClick={composition?.length > 0 ? toggleComboComposition : undefined}
        >
          {composition?.map(good => (
            <OrderItemDescriptionView
              key={good.hash}
              view={view}
              className={className}
              {...good}
            />
          ))}
        </div>
      </Collapse>
      <div className={cx('OrderItemDescription__compositionToggleWrapper')}>
        <AppLink
          href="#"
          className={cx('OrderItemDescription__compositionToggle', {
            OrderItemDescription__compositionToggle_disabled: composition
              ? composition?.length <= 0
              : !composition
          })}
          onClick={composition?.length > 0 ? toggleComboComposition : undefined}
        >
          <FormattedMessage
            id={isOpen ? 'cart.composition.rollUp' : 'cart.composition.more'}
          />
          <ToggleArrow
            className={cx('OrderItemDescription__compositionToggleArrow')}
            isOpen={isOpen}
          />
        </AppLink>
      </div>
    </>
  );
}

OrderItemDescription.defaultProps = {
  className: '',
  view: 'compact'
};

OrderItemDescription.propTypes = {
  className: PropTypes.string,
  composition: PropTypes.array,
  view: PropTypes.string,
  intl: PropTypes.object
};

export default injectIntl(OrderItemDescription);
